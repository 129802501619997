import React, { createContext, useContext, useEffect, useState } from "react";
import { analytics, firebaseApp } from "../lib/firebase-services";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  getAdditionalUserInfo,
} from "firebase/auth";
import { writeUserData } from "../lib/firestore-service";
import { logEvent, setUserId } from "firebase/analytics";

const AuthContext = createContext(null);
const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setUserId(analytics, user.uid);
        logEvent(analytics, "login", { method: "Google" });
      } else {
        setUser(null);
      }
    });
  }, []);

  const login = async () => {
    return signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        const providerData = getAdditionalUserInfo(result);
        if (providerData.isNewUser) {
          writeUserData({
            ...providerData.profile,
            phone: user.phoneNumber,
            email: user.email,
          });
          logEvent(analytics, "sign_up", { method: "Google" });
        }

        setUser(user);
      })
      .catch((error) =>
        logEvent(analytics, "exception", {
          description: "Falló el login: " + error.message,
          fatal: true,
        })
      );
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => console.log(error));
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
