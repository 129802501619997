import { Paper } from "@mui/material";

const Header = (props) => {
  return (
    <Paper
      variant="outlined"
      square
      sx={{
        position: "fixed",
        zIndex: 2,
        width: "100%",
        height: 60,
        bgcolor: "#F0F1F5",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // borderBottom: props.borderBottom && "1px solid #2c2c2c",
      }}
    >
      {props.children}
    </Paper>
  );
};

export default Header;
