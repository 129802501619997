import "./App.css";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChatContainer from "./pages/chat";
import HomeContainer from "./pages/home";
import { AuthProvider } from "./providers/auth";
import RequireAuth from "./components/Auth/RequireAuth";

function App() {
  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#0288C1",
        contrastText: "#FFFFFF",
      },
      neutral: {
        main: "#FFFFFF",
        contrastText: "#0288C1",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer
        position="bottom-left"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeContainer />} />
            <Route
              path="/chat"
              element={
                <RequireAuth>
                  <ChatContainer />
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
