import axios from "axios";
import { Configuration, OpenAIApi } from "openai";

const openAIConfig = new Configuration({
  //   apiKey: process.env.OPENAI_KEY,
});

const openapi = new OpenAIApi(openAIConfig);

export const chatCompletion = async ({ prompt }) => {
  const answer = await openapi.createCompletion({
    model: "text-davinci-003",
    prompt: prompt,
    temperature: 0,
    top_p: 1.0,
    max_tokens: 3000,
    frequency_penalty: 0.5,
    presence_penalty: 0.0,
  });

  const text = answer.data.choices[0].text;

  return { response: { text } };
};

export const chatTurboCompletion = async ({ prompt, user }) => {
  // const apiUrl = "https://api.openai.com/v1/chat/completions";
  const apiUrl = "https://us-central1-agurai.cloudfunctions.net/completion";
  const headers = {
    "Content-Type": "application/json",
    accept: "*/*",
    "User-Agent": "Agur client",
  };

  return axios
    .post(
      apiUrl,
      {
        messages: [...prompt],
        user,
      },
      { headers }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      return { err: error };
    });
};
