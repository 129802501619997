import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "./background.css";
import logo from "../../assets/agur-logo-color.svg";
import {
  Button,
  Fade,
  IconButton,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { ArrowForward, LogoutOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/auth";
import { useTranslation } from "react-i18next";

const lngs = [
  { value: "es", label: "Español" },
  { value: "en", label: "English" },
];

const HomeContainer = () => {
  const [checked, setChecked] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const redirectUrl = location.state?.path || "/chat";

  useEffect(() => {
    setChecked(true);
  }, []);

  const handleLogin = () => {
    if (!auth.user) {
      auth.login().then(() => {
        navigate(redirectUrl);
      });
    } else {
      navigate(redirectUrl);
    }
  };

  const handleLogout = () => {
    auth.logout();
  };

  const renderHeader = () => (
    <Box
      sx={{
        zIndex: 2,
        width: "100%",
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          paddingX: 2,
          maxWidth: "md",
        }}
      >
        {i18n.resolvedLanguage && (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={i18n.resolvedLanguage}
            label="Age"
            onChange={(selected) => i18n.changeLanguage(selected.target.value)}
            sx={{
              top: "15%",
              color: "white",
            }}
          >
            {lngs.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}

        {auth.user && (
          <IconButton
            onClick={handleLogout}
            sx={{
              position: "absolute",
              top: "50%",
              right: "16px",
              transform: "translateY(-50%)",
              color: "white",
            }}
          >
            <LogoutOutlined />
          </IconButton>
        )}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className={"container"}
    >
      {renderHeader()}
      <Fade
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 1000 } : {})}
      >
        <img
          style={{ marginTop: "30px" }}
          width={"250px"}
          height="98px"
          alt="logo"
          src={logo}
        />
      </Fade>
      <Fade
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 2000 } : {})}
      >
        <Typography
          sx={{ color: "white", padding: "25px", mb: "30px" }}
          align="center"
          variant="h6"
          fontWeight="800"
        >
          {t("Home description")}
        </Typography>
      </Fade>
      <Fade
        in={checked}
        style={{ transformOrigin: "0 0 0" }}
        {...(checked ? { timeout: 3000 } : {})}
      >
        <Button
          onClick={() => handleLogin()}
          sx={{
            fontWeight: "800",
            padding: "26px",
            fontSize: "1.0rem",
            width: "80%",
            maxWidth: "300px",
            justifyContent: "space-around",
            borderRadius: "50px",
          }}
          color="neutral"
          variant="contained"
          size="large"
          endIcon={<ArrowForward fontSize="large" color="primary" />}
        >
          {t("Home start-button")}
        </Button>
      </Fade>
      <Box sx={{ flexGrow: "2" }}></Box>
      <Box
        sx={{
          padding: "25px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          color: "white",
        }}
      >
        <Link sx={{ color: "white", mb: "10px" }}>{t("About us link")}</Link>
        <Link sx={{ color: "white" }}>{t("Terms link")}</Link>
      </Box>
    </Box>
  );
};

export default HomeContainer;
