import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-http-backend";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "Home description": "We are a community to connect Christians with AI.",
      "Home start-button": "Start now",
      "About us link": "About us",
      "Terms link": "Terms and privacy",
      "Chat alert":
        "Important: Nothing and no one replaces the voice of the Holy Spirit.",
      "Chat error": "Sorry, there was something wrong",
      "Prompt examples title": "Starts with: ",
      "First prompt": "I would like to know what says the bible about ",
      "Second prompt": "Tell me step by step ",
      "Third prompt": "Explain me according to the bible why ",
      "Input text placeholder": "ask for something...",
      "Input text info":
        "This chat has been pre-trained and pre-conditioned mainly to provide answers based on the Biblical context.",
    },
  },
  es: {
    translation: {
      "Home description":
        "Somos una comunidad que conecta al pueblo cristiano con la inteligencia artificial.",
      "Home start-button": "Empezar ahora",
      "About us link": "Acerca de nosotros",
      "Terms link": "Términos y condiciones",
      "Chat alert":
        "Importante: Nada ni nadie remplaza la voz del Espirítu Santo.",
      "Chat error": "Hubo un error para completar la solicitud",
      "Prompt examples title": "Puedes en empezar con:",
      "First prompt": "Me gustaría saber qué dice la biblia sobre ",
      "Second prompt": "Dime cuales son los pasos para ",
      "Third prompt": "Explícame según la biblia por qué ",
      "Input text placeholder": "pregunta algo...",
      "Input text info":
        "Este chat ha sido pre entrenado y precondicionado principalmente para que brinde respuestas según el contexto biblico.",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
//   .use(Backend)
  .init({
    resources,
    // lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: "es",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
