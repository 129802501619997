import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

import TypeWriter from "typewriter-effect";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import "./index.css";
import {
  Box,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  Paper,
  Alert,
} from "@mui/material";
import { chatTurboCompletion } from "../../api/chat";
import Header from "../../components/Header";
import {
  ArrowBack,
  ArrowForward,
  RestartAltOutlined,
  Star,
} from "@mui/icons-material";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../lib/firebase-services";
import { useAuth } from "../../providers/auth";
import { useTranslation } from "react-i18next";

const ROLES = {
  ASSISTANT: "assistant",
  USER: "user",
};

const ChatContainer = () => {
  // const username = localStorage.getItem("username");

  const navigate = useNavigate();
  const inputRef = useRef();
  const chatWrapperRef = useRef();
  const auth = useAuth();

  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation();

  const userName = auth?.user?.displayName?.split(" ")[0] || "";

  const initQuestions = [
    {
      id: 1,
      content: t("First prompt"),
    },
    {
      id: 2,
      content: t("Second prompt"),
    },
    {
      id: 3,
      content: t("Third prompt"),
    },
  ];

  // useEffect(() => {
  //   const textMessage = {
  //     role: ROLES.ASSISTANT,

  //     content: "Hola, soy Agur, en qué te puedo ayudar?",
  //   };
  //   if (messages.length === 0) {
  //     setMessages([textMessage]);
  //   }
  // }, [messages]);

  const getAnswer = async () => {
    setShowError(false);
    if (onRequest) return;

    logEvent(analytics, "search", { search_term: question });

    const newMessages = [
      ...messages,
      {
        role: ROLES.USER,
        content: question,
      },
    ];

    setMessages(newMessages);
    setQuestion("");
    setOnRequest(true);

    const { response, err } = await chatTurboCompletion({
      user: auth?.user?.uid,
      prompt: newMessages,
    });

    if (response) {
      setMessages([
        ...newMessages,
        {
          role: ROLES.ASSISTANT,
          content: response.text,
        },
      ]);
    }

    if (err) {
      setShowError(true);
      setOnRequest(false);
      logEvent(analytics, "exception", {
        description: "Falló el chat",
        fatal: true,
      });
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13) getAnswer();
  };

  const onSignOut = () => {
    navigate("/");
  };

  useEffect(() => {
    const handleScroll = (e) => {
      e.currentTarget.scroll({
        top: e.currentTarget.scrollHeight,
        // behavior: "smooth",
      });
    };
    setTimeout(() => {
      chatWrapperRef?.current?.addEventListener(
        "DOMNodeInserted",
        handleScroll
      );
    }, 200);
    return () => window.removeEventListener("DOMNodeInserted", handleScroll);
  }, []);

  const reloadConversation = () => {
    setShowError(false);
    setMessages([]);
  };

  const getBubbleColor = (type) => {
    switch (type) {
      case ROLES.ASSISTANT:
        return { bgcolor: "#F0F1F5" };
      case ROLES.USER:
        return { color: "#FFF", bgcolor: "#0D99FF" };

      default:
        return { bgcolor: "#F0F1F5" };
    }
  };

  const renderInitQuestion = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
        position: "absolute",
        top: "30%",
        left: "0%",
        zIndex: "100",
      }}
    >
      <Box
        sx={{
          width: "70%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <Star fontSize="medium" sx={{ marginRight: "10px" }} color="primary" />
        <Typography variant="h7" align="center">
          {t("Prompt examples title")}
        </Typography>
        <Star fontSize="medium" sx={{ marginLeft: "10px" }} color="primary" />
      </Box>
      {initQuestions.map((item) => (
        <Paper
          onClick={() => setQuestion(item.content)}
          key={item.id}
          variant="outlined"
          sx={{
            cursor: "pointer",
            width: "100%",
            maxWidth: "290px",
            padding: "15px",
            display: "flex",
            marginBottom: "10px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ padding: "3px" }}>{item.content}...</Typography>
          <ArrowForward color="primary" />
        </Paper>
      ))}
    </Box>
  );

  const formatText = (text) => {
    return text.replace(/\n/g, "<br>");
  };

  return (
    <Box
      sx={{
        //  height: window.innerHeight,
        height: "100vh",
        bgcolor: "#F5F6F1",
        position: "relative",
      }}
    >
      <Header bg borderBottom>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "relative",
            paddingX: 2,
            maxWidth: "md",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="700"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Chat
          </Typography>
          <IconButton
            onClick={onSignOut}
            color="primary"
            sx={{
              position: "absolute",
              top: "50%",
              left: "36px",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ArrowBack />
          </IconButton>

          <IconButton
            onClick={reloadConversation}
            color="primary"
            sx={{
              position: "absolute",
              top: "50%",
              right: "16px",
              transform: "translateY(-50%)",
            }}
          >
            <RestartAltOutlined />
          </IconButton>
        </Box>
      </Header>

      <Box display="flex" justifyContent="center">
        <Box
          width="100%"
          // maxWidth="md"
          ref={chatWrapperRef}
          sx={{
            height: "95%",
            position: "fixed",
            zIndex: 1,
            maxWidth: "md",
            width: "100%",
            overflowY: "auto",
            // bgcolor: "#F5F6F1",
            paddingTop: "60px",
            paddingBottom: "90px",
            "&::-webkit-scrollbar": {
              width: "0px",
            },
          }}
        >
          <Alert severity="warning">{t("Chat alert")}</Alert>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              maxWidth: "md",
              width: "100%",
            }}
          >
            {messages.map((item, index) => (
              <Box
                sx={{
                  alignSelf: item.role === ROLES.USER && "flex-end",
                }}
                key={index}
                padding={1}
              >
                <Box
                  sx={{
                    padding: 2,
                    ...getBubbleColor(item.role),
                    borderRadius: 3,
                    // width: "fit-content",
                  }}
                >
                  {index === messages.length - 1 ? (
                    item.role === ROLES.ASSISTANT ? (
                      <TypeWriter
                        onInit={(writer) => {
                          writer
                            .typeString(formatText(item.content))
                            .callFunction(() => {
                              const typerStyle = document.querySelector(
                                ".Typewriter__cursor"
                              );
                              if (typerStyle) {
                                typerStyle.style.display = "none";
                              }

                              setOnRequest(false);
                              setTimeout(() => {
                                inputRef.current.focus();
                              }, 100);
                            })
                            .start();
                        }}
                        options={{ delay: 10 }}
                      />
                    ) : (
                      item.content
                    )
                  ) : (
                    item.content
                  )}
                </Box>
              </Box>
            ))}
          </Box>
          {showError && <Alert severity="error">{t("Chat error")}</Alert>}
          {messages.length < 1 && renderInitQuestion()}
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor="#FFF"
        zIndex={3}
        sx={{
          position: "fixed",
          bottom: 0,
          boxShadow: "1px 0px 20px #00000033",
        }}
        width="100%"
      >
        <Box width="100%" maxWidth="md">
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              multiline
              maxRows={4}
              inputRef={inputRef}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              endAdornment={
                onRequest ? (
                  <Box>
                    <div sx={{ padding: "5px" }} className="dot-falling"></div>
                  </Box>
                ) : (
                  <IconButton onClick={getAnswer}>
                    <SendOutlinedIcon color="primary" />
                  </IconButton>
                )
              }
              autoFocus
              disabled={onRequest}
              onKeyUp={onEnterPress}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder={`${userName} ${t("Input text placeholder")}`}
            />
          </FormControl>
          <Box>
            <Typography
              textAlign="center"
              sx={{ fontSize: "12px", color: "gray" }}
              gutterBottom
            >
              {t("Input text info")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatContainer;
