import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getFunctions } from "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyA_rv9RRCt65IqsvPduk11duEtioVkvTtk",
  authDomain: "agurai.firebaseapp.com",
  projectId: "agurai",
  storageBucket: "agurai.appspot.com",
  messagingSenderId: "403167820716",
  appId: "1:403167820716:web:ca625a2d04b19d75985809",
  measurementId: "G-H44J23B6T3",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const perf = getPerformance(firebaseApp);
export const functions = getFunctions(firebaseApp);

