import { addDoc, collection, getFirestore } from "firebase/firestore";

import { firebaseApp } from "./firebase-services";

const db = getFirestore(firebaseApp);

export function writeUserData({
  family_name,
  given_name,
  locale,
  name,
  phone,
  picture,
  email,
}) {
  addDoc(collection(db, "users"), {
    name,
    family_name,
    given_name,
    phone,
    locale,
    picture,
    email,
  })
    .then(() => {})
    .catch((error) => console.log(error));
}
